<template>
    <div v-if="dados && !loading">
        <b-table bordered small :fields="fields" :items="dados" class="custom-table" >
            <template #thead-top="data">
                <b-tr>
                    <b-th colspan="9" variant="primary"><b-p class="d-flex justify-content-center">Contratos
                            CCEE</b-p></b-th>
                </b-tr>
            </template>

            <template #cell(selected)="data">
                <b-form-checkbox v-model="data.item.selected" @change="onCheckboxChange(data.item)"></b-form-checkbox>
            </template>

            <template #cell(vigenciaIniMontante)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(vigenciaFimMontante)="data">
                <p class="data">{{ data.value }}</p>
            </template>

            <template #cell(montanteMWm)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [{ key: 'selected', label: '' },
            { key: 'cliqccee', label: 'Cliqccee' },
            { key: 'perfilVendedor', label: 'Vendedor' },
            { key: 'perfilComprador', label: 'Comprador'},
            { key: 'submercado', label: 'SU', headerTitle: 'Submercado' },
            { key: 'statusContrato', label: 'Status'},
            { key: 'montanteMWm', label: 'MWM', headerTitle: 'Montante MWm' },
            { key: 'vigenciaIniMontante', label: 'Vig Ini', headerTitle: 'Vigência Inicial' },
            { key: 'vigenciaFimMontante', label: 'Vig Fim', headerTitle: 'Vigência Final' }],
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        dados: {
            type: Array,
            default: null
        }
    },
    methods: {
        onRowSelected(items) {
            //console.log('Selected rows:', items);
            this.$emit('rowSelected', { items: items });
        },
        onCheckboxChange(selectedItem) {
            this.dados.forEach((objeto) => {
                if (objeto !== selectedItem) this.$set(objeto, 'selected', false);
            });
            this.onRowSelected(this.dados.filter((item) => item.selected)[0]);
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return parseFloat(number).toFixed(decimals);
            }
        }
    }
};
</script>

<style scoped>
.data {
    width: 55px;
}

.table {
    font-size: 9px;
    width: 10vw;
}

</style>