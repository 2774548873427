<template>
    <div v-if="dados && !loading" id="tab">
        <b-table bordered small :fields="fields" :items="dados" class="table">
            <template #thead-top="data">
                <b-tr>
                    <b-th colspan="1">
                        <b-form-checkbox  v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
                    </b-th>
                    <b-th colspan="10" variant="primary"><b-p class="d-flex justify-content-center">{{ nomeTabela
                            }}</b-p></b-th>
                </b-tr>
            </template>

            <template #cell(selected)="data">
                <div v-if="!possuiMatching(data.item) && !data.item.netEnergetico">
                    <b-form-checkbox v-model="data.item.selected"
                        @change="onCheckboxChange(data.item)"></b-form-checkbox>
                </div>
            </template>

            <template #cell(sys.inicio)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(sys.fim)="data">
                <p class="data">{{ data.value }}</p>
            </template>

            <template #cell(sys.volume)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>

            <template #cell(valorTotalRegistro)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>

            <template #cell(matching)="data">
                <IconValid :value="possuiMatching(data.item)"></IconValid>
            </template>

            <template #cell(net)="data">
                <IconValid :value="data.item.netEnergetico"></IconValid>
            </template>
        </b-table>
    </div>
</template>

<script>
import IconValid from '@/components/iconValid';

export default {
    data() {
        return {
            fields: [{ key: 'selected', label: '' },
            { key: 'codBoleta', label: 'Boleta' },
            { key: 'sys.tipoContrato', label: 'PC', headerTitle: 'Período Contrato' },
            { key: 'sys.submercado', label: 'SU', headerTitle: 'Submercado' },
            { key: 'sys.inicio', label: 'Data Ini' },
            { key: 'sys.fim', label: 'Data Fim' },
            { key: 'sys.volume', label: 'Vol', headerTitle: 'Volume' },
            { key: 'sys.preco', label: 'Preço' },
            { key: 'valorTotalRegistro', label: 'Valor', headerTitle: 'Valor Total Registro' },
            { key: 'matching', label: 'MAT', headerTitle: 'Possui Matching' },
            { key: 'net', label: 'NET', headerTitle: 'Possui NET Energético' }],
            selectAll: false
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        dados: {
            type: Array,
            default: null
        },
        nomeTabela: {
            type: String,
            default: ''
        }
    },
    components: {
        IconValid
    },
    methods: {
        toggleSelectAll() {
            var filtrados = this.dados.filter((item) => item.cliqccee == null);
            console.log(filtrados)
            if(filtrados.length == 0){
                this.selectAll = false;
                return;
            }

            if (this.selectAll) {
                this.dados.forEach((objeto) => {
                    this.$set(objeto, 'selected', true);
                });
            } else {
                this.dados.forEach((objeto) => {
                    this.$set(objeto, 'selected', false);
                });
            }

            this.onRowSelected(this.dados.filter((item) => item.selected && item.cliqccee == null));
        },
        onRowSelected(items) {
            //console.log('Selected rows:', items);
            this.$emit('rowSelected', { items: items });
        },
        onCheckboxChange(item) {
            this.onRowSelected(this.dados.filter((item) => item.selected));
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
            }
        },
        possuiMatching(boleta) {
            return boleta.cliqccee != null && (boleta.netEnergetico == null || boleta.netEnergetico == false);
        }
    }
};
</script>

<style scoped>
.data {
    width: 55px;
}

.table {
    font-size: 9px;
    width: 10vw;
}
</style>