<template>
    <div>
        <b-card class="mb-3 mt-3 card-pequeno" title="Ações">
            <b-container fluid class="ml-1 mt-1">
                <b-row align-v="center" class="my-1">
                    <b-col md="2">
                        <b-form-group label="Mês de Referência" label-for="filtroMesRef" class="buttom">
                            <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Ano de Referência" label-for="filtroAnoRef" class="buttom">
                            <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Fonte" label-for="fonte" class="buttom">
                            <b-form-select id="fonte" :options="fontes" v-model="fonte" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Vendedor" label-for="vendedor" class="form">
                            <b-form-input id="vendedor" type="text" v-model="vendedor" @keyup.enter="list"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Comprador" label-for="comprador" class="form">
                            <b-form-input id="comprador" type="text" v-model="comprador" @keyup.enter="list"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row align-v="center" class="my-1">
                    <b-col md="2">
                        <b-button @click="list" class="buttom">Listar</b-button>
                    </b-col>

                    <b-col md="2" v-if="nets">
                        <b-button @click="apagaNet" class="buttom">Desfazer Net</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <NetTable v-if="nets" :loading="loading" :dados="nets" @rowSelected="atualizarNets" @downloadXml="downloadXml"></NetTable>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import meses from '@/utils/meses';
import { list, apagaNet, downloadXml } from './NetEnergetico.service';
import NetTable from './NetTable.vue';

Vue.use(VueMask);

export default {
    data() {
        return {
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            filtroAnoRef: new Date().getFullYear(),
            mesRefInput: '',
            loading: false,
            fontes: [
                { value: null, text: 'Selecione uma' },
                { value: 'CO', text: 'CO' },
                { value: 'I0', text: 'I0' },
                { value: 'I5', text: 'I5' },
                { value: 'I1', text: 'I1' },
                { value: 'INE', text: 'INE' },
                { value: 'CQ5', text: 'CQ5' }],
            fonte: null,
            nets: null,
            selecaoNets: null,
            vendedor: null,
            comprador: null
        }
    },
    components: {
        NetTable
    },
    created() {
        this.atualizarDataRef();
    },
    methods: {
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        },
        getFiltro(){
            return {
                fonte: this.fonte,
                vendedor: this.vendedor,
                comprador: this.comprador
            };
        },
        list() {
            if (this.fonte == null) return;

            this.loading = true;

            list(this.mesRefInput, this.getFiltro())
                .then((response) => {
                    if (response.status) {
                        this.nets = response.content;
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        apagaNet() {
            if (this.selecaoNets == null) return;

            this.loading = true;

            apagaNet(this.selecaoNets)
                .then((response) => {
                    if (response.status) {
                        
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.list();
                });
        },
        downloadXml(net) {
            
            this.loading = true;

            downloadXml(net)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return parseFloat(number).toFixed(decimals);
            }
        },
        atualizarNets(items) {
            this.selecaoNets = items.items;
        }
    }
}
</script>

<style scoped>
.buttom{
    width: 12vw;
}
</style>