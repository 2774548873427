<template>
    <div>
        <PageHeader :title="'NET Energético'" :items="breadcrumb" />

        <div>
            <b-tabs content-class="mt-3">
                <b-tab title="Criar" active  v-if="hasPermission('backoffice_edit')">
                    <CreateNetEnergetico></CreateNetEnergetico>
                </b-tab>
                <b-tab title="Listar" v-if="hasPermission('backoffice_edit')">
                    <ListNetEnergetico></ListNetEnergetico>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import CreateNetEnergetico from './CreateNetEnergetico.vue';
import ListNetEnergetico from './ListNetEnergetico.vue';
import { hasPermission } from '@/features/auth/auth.acl';

Vue.use(VueMask);

export default {
    data() {
        return {
            breadcrumb: [
                {
                    text: 'Backoffice',
                    href: '#'
                },
                {
                    text: 'Net Energértico',
                    active: true
                }
            ]
        }
    },
    components: {
        PageHeader, CreateNetEnergetico, ListNetEnergetico
    },
    methods: {
        hasPermission(requiredAcl) {
            return hasPermission(requiredAcl);
        }
    }
}
</script>
