<template>
    <div>
        <b-card class="mb-3 mt-3 card-pequeno" title="Ações">
            <b-container fluid class="ml-1 mt-1">
                <b-row align-v="center" class="my-1">
                    <b-col md="2">
                        <b-form-group label="Mês de Referência" label-for="filtroMesRef" class="form">
                            <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Ano de Referência" label-for="filtroAnoRef" class="form">
                            <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Fonte" label-for="fonte" class="form">
                            <b-form-select id="fonte" :options="fontes" v-model="fonte" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-button @click="getPerfis" class="pdl">Buscar Perfis</b-button>
                    </b-col>
                </b-row>

                <b-row align-v="center" class="my-1" v-if="temPerfis">
                    <b-col md="2">
                        <b-form-group label="Perfil" label-for="perfil" class="form">
                            <b-form-select id="perfil" :options="perfis" v-model="perfil" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-button @click="getEntrada" class="pdl">Começar NET</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning" class="ml-2 mt-3" />

        <b-card class="mb-3 mt-3" v-if="entrada" title="Selecionar Boletas e Contratos">

            <b-container fluid class="ml-1 mt-1">
                <b-row align-v="center" class="my-1">
                    <b-col col="4">
                        <ContratoCceeTable :loading="loading" :dados="contratos" @rowSelected="atualizarContrato">
                        </ContratoCceeTable>
                    </b-col>

                    <b-col col="4">
                        <BoletaTable :loading="loading" :dados="boletasVenda" :nomeTabela="'Boletas de Venda'"
                            @rowSelected="atualizarVendas"></BoletaTable>
                    </b-col>

                    <b-col col="4">
                        <BoletaTable :loading="loading" :dados="boletasCompra" :nomeTabela="'Boletas de Compra'"
                            @rowSelected="atualizarCompras"></BoletaTable>
                    </b-col>
                </b-row>
            </b-container>



        </b-card>

        <b-card class="mb-3 mt-3 card-pequeno" v-if="entrada">
            <b-container fluid class="ml-3 mt-2">
                <b-row>
                    <b-col md="2">
                        <b-card header="Saldo Energético" header-bg-variant="dark" header-text-variant="white"
                            align="center" class="card-pequeno">
                            <b-card-text>{{ formatNumber(saldoEnergia, 3) }} Mwm</b-card-text>
                        </b-card>
                    </b-col>

                    <b-col md="2">
                        <b-card header="Saldo Financeiro" header-bg-variant="dark" header-text-variant="white"
                            align="center" class="card-pequeno">
                            <b-card-text>{{ formatNumber(saldoFinanceiro, 3) }} R$</b-card-text>
                        </b-card>
                    </b-col>

                    <b-col md="2">
                        <b-container fluid class="ml-1 mt-1" v-if="validaCriacao()">
                            <b-row align-v="center" class="my-1">
                                <b-button @click="criaNet" class="pdl">Criar NET</b-button>
                            </b-row>
                        </b-container>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import meses from '@/utils/meses';
import { getEntradaCreate, getPerfis, criaNet } from './NetEnergetico.service';
import BoletaTable from './BoletaTable';
import ContratoCceeTable from './ContratoCceeTable';

Vue.use(VueMask);

export default {
    data() {
        return {
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            filtroAnoRef: new Date().getFullYear(),
            mesRefInput: '',
            loading: false,
            fontes: [
                { value: null, text: 'Selecione uma' },
                { value: 'CO', text: 'CO' },
                { value: 'I0', text: 'I0' },
                { value: 'I5', text: 'I5' },
                { value: 'I1', text: 'I1' },
                { value: 'INE', text: 'INE' },
                { value: 'CQ5', text: 'CQ5' }],
            fonte: null,
            temPerfis: false,
            perfil: null,
            boletasCompra: null,
            boletasVenda: null,
            entrada: false,
            selecaoCompra: null,
            selecaoVenda: null,
            contratosVenda: [],
            contratosCompra: [],
            contratos: [],
            selecaoContrato: null,
            saldoEnergia: 0,
            saldoFinanceiro: 0,
            perfis: []
        }
    },
    components: {
        BoletaTable, ContratoCceeTable
    },
    created() {
        this.atualizarDataRef();
    },
    methods: {
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        },
        criaObjetoPerfis(arrayPerfis) {
            this.perfis = [{ value: null, text: 'Selecione um' }];
            arrayPerfis.forEach(p => {
                this.perfis.push({ value: p, text: p })
            });
        },
        getPerfis() {
            if (this.fonte == null) return;

            this.loading = true;

            getPerfis(this.mesRefInput, this.fonte)
                .then((response) => {
                    if (response.status) {
                        this.criaObjetoPerfis(response.content);
                        this.temPerfis = true;
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getEntrada() {
            if (this.perfil == null) return;
            this.selecaoCompra = null;
            this.selecaoVenda = null;
            this.selecaoContrato = null;
            this.saldoEnergia = 0;
            this.saldoFinanceiro = 0;

            this.loading = true;

            getEntradaCreate(this.mesRefInput, this.perfil, this.fonte)
                .then((response) => {
                    if (response.status) {
                        //console.log(response)

                        this.boletasCompra = response.content.boletas.filter(item => item.tipoBoleta === 'C');
                        this.boletasVenda = response.content.boletas.filter(item => item.tipoBoleta === 'V');
                        this.contratos = response.content.contratos;
                        this.contratosVenda = this.contratos.filter(str => str.perfilVendedor.toLowerCase().includes("libra"));
                        this.contratosCompra = this.contratos.filter(str => !str.perfilVendedor.toLowerCase().includes("libra"));
                        this.entrada = true;
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getResultadoFinal() {
            let result = 0.0;
            let resulCompras = 0.0;
            let resultVendas = 0.0;

            if (this.selecaoCompra != null) {
                for (let b of this.selecaoCompra) {
                    resulCompras -= b.sys.volume;
                }
            }

            if (this.selecaoVenda != null) {
                for (let b of this.selecaoVenda) {
                    resultVendas += b.sys.volume;
                }
            }

            result = resulCompras + resultVendas;

            this.saldoEnergia = result;
        },
        getResultadoFinalFinanceiro() {
            let result = 0.0;
            let resulCompras = 0.0;
            let resultVendas = 0.0;

            if (this.selecaoCompra != null) {
                for (let b of this.selecaoCompra) {
                    resulCompras -= b.valorTotalRegistro;
                }
            }

            if (this.selecaoVenda != null) {
                for (let b of this.selecaoVenda) {
                    resultVendas += b.valorTotalRegistro;
                }
            }

            result = resulCompras + resultVendas;

            this.saldoFinanceiro = result;
        },
        selecionaContratos(){
            if(this.saldoEnergia > 0){
                this.contratos = this.contratosVenda;
            }else if(this.saldoEnergia < 0){
                this.contratos = this.contratosCompra;
            }else if(this.saldoEnergia == 0){
                this.contratos = this.contratosVenda.concat(this.contratosCompra);
            }
        },
        atualizarCompras(items) {
            this.selecaoCompra = items.items;
            this.getResultadoFinal();
            this.getResultadoFinalFinanceiro();

           this.selecionaContratos();
        },
        atualizarVendas(items) {
            this.selecaoVenda = items.items;
            this.getResultadoFinal();
            this.getResultadoFinalFinanceiro();

            this.selecionaContratos();
        },
        atualizarContrato(items) {
            this.selecaoContrato = items.items;
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
            }
        },
        criaNet() {
            this.loading = true;
            let boletas = [];
            boletas = boletas.concat(this.selecaoCompra);
            boletas = boletas.concat(this.selecaoVenda);

            criaNet(this.selecaoContrato, boletas)
                .then((response) => {
                    if (response.status) {

                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        validaCriacao() {
            return (this.selecaoCompra != null && this.selecaoCompra.length !== 0) &&
                (this.selecaoVenda != null && this.selecaoVenda.length !== 0) &&
                (this.selecaoContrato != null && this.selecaoContrato.length !== 0);
        }

    }
}
</script>

<style scoped>
div.card-body {
    padding: 0;
}

div.col {
    padding: 0;
}

.w {
    width: 100%;
}

.pdl {
    width: 12vw;
}
</style>