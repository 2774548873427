<template>
    <div id="tab">
        <b-table bordered small :fields="fields" :items="boletas" class="table">

            <template #cell(sys.inicio)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(sys.fim)="data">
                <p class="data">{{ data.value }}</p>
            </template>

            <template #cell(sys.volume)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>

            <template #cell(valorTotalRegistro)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
            { key: 'codBoleta', label: 'Boleta' },
            { key: 'sys.tipoContrato', label: 'PC', headerTitle: 'Período Contrato' },
            { key: 'tipoBoleta', label: 'OP', headerTitle: 'Tipo Operação' },
            { key: 'sys.submercado', label: 'SU', headerTitle: 'Submercado' },
            { key: 'sys.siglaPerfilParte', label: 'PerfilP', headerTitle: 'Sigla Perfil Parte' },
            { key: 'sys.siglaPerfilContraParte', label: 'PerfilCP', headerTitle: 'Sigla Perfil Contra Parte'},
            { key: 'sys.inicio', label: 'Data Ini' },
            { key: 'sys.fim', label: 'Data Fim' },
            { key: 'sys.volume', label: 'Vol', headerTitle: 'Volume' },
            { key: 'sys.preco', label: 'Preço' },
            { key: 'valorTotalRegistro', label: 'Valor', headerTitle: 'Valor Total Registro' }],
            boletas: []
        }
    },
    props: {
        dados: {
            type: Object,
            default: null
        }
    },
    created() {
        this.boletas.push(...this.dados.boletas);
    },
    methods: {
        onRowSelected(items) {
            //console.log('Selected rows:', items);
            this.$emit('rowSelected', { items: items });
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
            }
        }
        
    }
};
</script>

<style scoped>

</style>